import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Animated, Dimensions, Platform } from "react-native";
import { HIDE_SNACKBAR_POSITION, SHOW_SNACKBAR_POSITION } from "./constants";
import Content from "./Content";

// Snackbar with TranslateY animation;
class Snackbar extends Component {
  constructor(props) {
    super(props);

    this.displaySnackbar = props.visible;
    this.timer = null;
    this.mounted = false;

    const position = this.displaySnackbar
      ? SHOW_SNACKBAR_POSITION
      : HIDE_SNACKBAR_POSITION;

    this.state = {
      position: new Animated.Value(position)
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.visible === true) {
      this.displaySnackbar = true;
    }
    if (nextProps.visible === false) {
      this.hideSnackbar();
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    if (this.displaySnackbar) {
      this.showSnackbar();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.timer);
  }

  handleTimer = () => {
    const { duration, onCompletion } = this.props;
    if (onCompletion) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        onCompletion(); // Snackbar depends on logic in onCompletion to hide itself
      }, duration);
    }
  };

  showSnackbar = () => {
    const { position } = this.state;
    Animated.timing(position, {
      toValue: SHOW_SNACKBAR_POSITION,
      duration: 200
    }).start(this.handleTimer);
  };

  hideSnackbar = () => {
    const { position } = this.state;
    Animated.timing(position, {
      toValue: HIDE_SNACKBAR_POSITION,
      duration: 200
    }).start(() => {
      this.displaySnackbar = false;
      if (this.mounted) {
        this.forceUpdate();
      }
    });
  };

  render() {
    const {
      visible,
      duration,
      onCompletion,
      children,
      ...restProps
    } = this.props;
    const { position } = this.state;

    const overlayStyles = {
      transform: [{ translateY: position }],
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      marginHorizontal: "auto",
      ...Platform.select({
        web: {
          width: Dimensions.get("window").width < 800 ? "100%" : 860
        },
        android: {
          width: "100%"
        }
      }),
      zIndex: 10,
      padding: 10,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "transparent"
    };

    if (!this.displaySnackbar) {
      return null;
    }

    return (
      <Animated.View style={overlayStyles}>
        <Content {...restProps}>{children}</Content>
      </Animated.View>
    );
  }
}

// // Snackbar without animation; to be used when AnimatedSnackbar have problems
// const Snackbar = ({
//   visible,
//   duration,
//   onCompletion,
//   children,
//   ...restProps
// }) => {
//   useEffect(() => {
//     let timer = null;
//     if (visible && onCompletion) {
//       clearTimeout(timer);
//       timer = setTimeout(() => {
//         onCompletion();  // SSnackbar depends on logic in onCompletion to hide itself
//       }, duration);
//     }
//     return () => clearTimeout(timer);
//   }, [visible]);

//   if (!visible) {
//     return null;
//   }

//   const overlayStyles = {
//     transform: [{
//       translateY: visible ? SHOW_SNACKBAR_POSITION : HIDE_SNACKBAR_POSITION,
//       alignItems: "center",
//       justifyContent: "center",
//       position: "absolute",
//       left: 0,
//       width: "100%",
//       zIndex: 10,
//       padding: 10,
//       bottom: 0
//     }]
//   };

//   return (
//     <View style={overlayStyles}>
//       <Content {...restProps}>
//         {children}
//       </Content>
//     </View>
//   );
// };

const WebSnackbarWithPortal = (props) => {
  if (!process.browser) {
    return null;
  }

  return ReactDOM.createPortal(
    <Snackbar {...props} />,
    document.getElementById("__next")
  );
};

export default WebSnackbarWithPortal;
