import PropTypes from "prop-types";
import SSnackbar from "./Snackbar";
import {
  LENGTH_INDEFINITE,
  LENGTH_LONG,
  LENGTH_LONGEST,
  LENGTH_SHORT
} from "./Snackbar/constants";

SSnackbar.defaultProps = {
  visible: false,
  backgroundColor: "primary",
  text: "",
  textColor: "white",
  duration: LENGTH_LONG,
  onCompletion: undefined,
  action: undefined,
  showThumbsUp: false
}

SSnackbar.propTypes = {
  visible: PropTypes.bool,
  backgroundColor: PropTypes.string,
  text: PropTypes.string,
  showThumbsUp: PropTypes.bool,
  textColor: PropTypes.string,
  duration: PropTypes.oneOf([LENGTH_INDEFINITE, LENGTH_LONG, LENGTH_SHORT, LENGTH_LONGEST]),
  onCompletion: PropTypes.func, // Snackbar depends on logic in onCompletion to hide itself
  action: PropTypes.shape({
    text: PropTypes.string,
    textColor: PropTypes.string,
    onPress: PropTypes.func
  })
};

export default SSnackbar;
export { LENGTH_INDEFINITE, LENGTH_SHORT, LENGTH_LONGEST, LENGTH_LONG }
