import React from "react";
import { StyleSheet, View } from "react-native";
import { getColor } from "../../../common/utils";
import SButton from "../../../SButton";
import SText from "../../../SText";
import { SuccessCheck, ErrorTriangle } from "../../../../icons";

const Content = ({ text, textColor, backgroundColor, action, iconType }) => (
  <View
    style={[
      styles.container,
      {
        backgroundColor: getColor(backgroundColor)
      }
    ]}
  >
  {iconType &&
    (iconType === "success" ? (
      <SuccessCheck style={{ marginRight: 8 }} />
    ) : (
      <ErrorTriangle style={{ marginRight: 8 }} />
    ))}
  <SText size={14} containerStyle={{ flex: 1 }} color={textColor}>

      {text}
    </SText>
    {action ? (
      <SButton
        type="text"
        text={action.text}
        textProps={{
          weight: "500",
          color: getColor(action.textColor)
        }}
        onPress={action.onPress}
      />
    ) : null}
  </View>
);

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingVertical: 15,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 4,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2
  }
});

export default Content;
